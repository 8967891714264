export const getFormattedSuccession = (options, nameAttr) => {
  const formattedElements = options?.map((item) => {
    const formattedItem = {
      id: item.id,
      value: item[nameAttr],
      label: item[nameAttr],
    };
    formattedItem.data = item;

    return formattedItem;
  });
  return formattedElements;
};

export const estimatedTime = [
  {
    name_es: "6 meses",
    name_en: "6 months",
    name_pt: "6 meses",
    value: "6 months",
  },
  {
    name_es: "1 año",
    name_en: "1 year",
    name_pt: "1 ano",
    value: "1 year",
  },
  {
    name_es: "1-3 años",
    name_en: "1-3 years",
    name_pt: "1-3 anos",
    value: "1-3 years",
  },
  {
    name_es: "3-5 años",
    name_en: "3-5 years",
    name_pt: "3-5 anos",
    value: "3-5 years",
  },
  {
    name_es: "5 años",
    name_en: "5 years",
    name_pt: "5 anos",
    value: "5 years",
  },
];

export const generateEstimatedTimeByLanguage = (language) => {
  const languageKey = `name_${language}`;

  return estimatedTime.map((item) => ({
    name: item[languageKey],
    value: item.value,
  }));
};

export const calculateTimePassedPercentageAndRemainingTime = (data) => {
  const now = new Date();
  const createdAt = new Date(data.updated_at);
  // FIXME: remove, only by test
  // createdAt.setMonth(createdAt.getMonth() - 2);
  const maxTime = data.max_time;
  const timeUnit = data.time_unit;

  let totalMonths;
  if (timeUnit === "months" || timeUnit === "month") {
    totalMonths = maxTime;
  } else {
    totalMonths = maxTime * 12;
  }

  const monthsPassed = (
    now.getFullYear() - createdAt.getFullYear()) * 12 + (now.getMonth() - createdAt.getMonth());

  const percentagePassed = data?.total_agreements > 0
    ? (data.total_finished_agreements / data.total_agreements) * 100 : 0;

  const remainingTimeInMonths = totalMonths - monthsPassed;
  let remainingTimeTextKey;
  let remainingTimeValue;

  if (remainingTimeInMonths <= 0) {
    remainingTimeTextKey = "time_completed";
    remainingTimeValue = 0;
  } else if (timeUnit === "years" || timeUnit === "year") {
    const remainingYears = Math.ceil(remainingTimeInMonths / 12);
    remainingTimeTextKey = remainingYears > 1 ? "time_years" : "time_year";
    remainingTimeValue = remainingYears;
  } else {
    remainingTimeTextKey = remainingTimeInMonths > 1 ? "time_months" : "time_month";
    remainingTimeValue = remainingTimeInMonths;
  }

  return {
    percentage: percentagePassed.toFixed(0),
    remainingTimeKey: remainingTimeTextKey,
    remainingTimeValue,
  };
};

export const getParsedEstimatedTime = (minTime, maxTime, timeUnit) => {
  if (minTime === maxTime) {
    return `${minTime} ${timeUnit}`;
  }
  return `${minTime}-${maxTime} ${timeUnit}`;
};

export const getEstimatedTime = (successionData, estimatedTimeOptions) => {
  const {
    min_time: minTime,
    max_time: maxTime,
    time_unit: timeUnit,
  } = successionData;
  const parsedEstimatedTime = getParsedEstimatedTime(minTime, maxTime, timeUnit);
  const matchingOption = estimatedTimeOptions.find(
    (option) => option.value === parsedEstimatedTime,
  );
  return matchingOption;
};
