import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import SkeletonLoader from "components/SkeletonLoader";
import {
  SKELETON_VARIANT,
  VARIANT,
} from "common/constants";
import { getAvatar } from "common/utils";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { MAX_EMPLOYEES } from "views/Cover/functions";
import EvaluationCard from "../EvaluationCard";
import {
  StyledAvatar,
  StyledBoxContainer,
  StyledOneContent,
  StyledProgressContainer,
} from "./styles";

const EvaluationsSection = (props) => {
  const {
    t, summaryStates, setShowAll, isMobile, getEvaluationResults, deleteEvaluationResult,
  } = props;

  const getAllEmployees = (process) => {
    const processByType = process.find((item) => item.type === summaryStates.showType);
    return processByType && (
      <EvaluationCard
        key={ processByType.type }
        t={ t }
        data={ processByType }
        size={ MAX_EMPLOYEES }
        showAll={ summaryStates.showAll }
        setShowAll={ setShowAll }
        processId={ summaryStates?.evaluation?.id }
        getEvaluationResults={ getEvaluationResults }
        deleteEvaluationResult={ deleteEvaluationResult }
        canAddPeers={ summaryStates?.evaluation.canAddPeers }
      />
    );
  };

  const cardsOrButtonRender = summaryStates.justOne
    && (summaryStates.justOne.type !== EVALUATION_TYPE.PAIRS) ? (
      <StyledOneContent>
        <Typography variant={ VARIANT.h5 }>{summaryStates.justOne?.label}</Typography>
        <StyledAvatar>
          <Avatar
            alt={ summaryStates.justOne.employees[0]?.name }
            src={ getAvatar(summaryStates.justOne.employees[0]) }
          />
          {summaryStates.justOne.employees[0]?.name}
        </StyledAvatar>
        {!summaryStates.justOne?.isCompleted && summaryStates.justOne?.link && (
          <Button
            href={ summaryStates.justOne?.link }
            variant={ VARIANT.contained }
          >
            {t("common:common.starts")}
          </Button>
        )}
      </StyledOneContent>
    ) : (
      <>
        <StyledBoxContainer>
          {summaryStates.processList ? summaryStates.processList.map((item) => (
            <EvaluationCard
              key={ item.type }
              t={ t }
              data={ item }
              size={ item.show }
              setShowAll={ setShowAll }
              processId={ summaryStates?.evaluation?.id }
              getEvaluationResults={ getEvaluationResults }
            />
          )) : (
            <SkeletonLoader
              numberOfSkeletons={ isMobile ? 1 : 4 }
              variant={ SKELETON_VARIANT.rectangular }
              height={ 180 }
              columnWidth={ isMobile ? 12 : 3 }
            />
          )}
        </StyledBoxContainer>
        {summaryStates.percentage !== null && (
          <StyledProgressContainer>
            <LinearProgress variant={ VARIANT.determinate } value={ summaryStates.percentage } />
            <Typography variant={ VARIANT.bodyOne }>
              {`${summaryStates.percentage}% ${t("preboarding:states.completed")}`}
            </Typography>
          </StyledProgressContainer>
        )}
      </>
    );

  return summaryStates.showAll ? (
    <StyledBoxContainer>
      { getAllEmployees(summaryStates.processList) }
    </StyledBoxContainer>
  ) : cardsOrButtonRender;
};

EvaluationsSection.propTypes = {
  t: PropTypes.func.isRequired,
  summaryStates: PropTypes.object.isRequired,
  getEvaluationResults: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  setShowAll: PropTypes.func,
  deleteEvaluationResult: PropTypes.func.isRequired,
};

EvaluationsSection.defaultProps = {
  isMobile: false,
  setShowAll: null,
};

export default EvaluationsSection;
