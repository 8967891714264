import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";
import palette from "theme/palette";
import { Link } from "react-router-dom";

export const StyledContent = styled.div`
  position: relative;
  h6 {
    font-size: 11px;
    font-weight: 400;
    color: ${palette.linearProgress.text}
    margin-bottom: 2px;
  }
  h6, .MuiLinearProgress-root, .MuiLinearProgress-bar {
    opacity: ${(props) => (props.hasOpacity ? 0.5 : 1) };
  }
`;

export const StyledText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSubText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-size: 11px;
    color: ${palette.black};
    font-weight: 700;
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  min-width: 100px;
  border-radius: 5px;
  &.MuiLinearProgress-root {
    height: ${(props) => (props.isSublevel ? "8px" : "10px")};
    background-color: ${palette.linearProgress.incomplete};
  }
  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${(props) => (props.isPrimaryColor ? palette.text.link : props.customColor)};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  text-align: center;
  padding: 10px;
  font-size: 11px;
  font-weight: 700;
  color: ${palette.text.red};
`;

// TODO: add alert icon
export const StyledAlert = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  cursor: pointer;
  text-align: center;
  color: ${palette.text.red};
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 1;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const StyledDescription = styled.h5`
  margin-top: 8px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
